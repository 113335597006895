import Viewport from '../core/Viewport';
import { isSmall } from '../lib/helpers';

export default el => {
    
    const maybeStickStuff = () => {
        const { height: viewportHeight } = Viewport;
        const stickies = el.querySelectorAll('[class*="sticky"]');
        stickies.forEach(sticky => {
            if (isSmall()) {
                sticky.style.position = '';
                return;
            }
            const { height: parentHeight } = sticky.parentNode.getBoundingClientRect();
            const { height } = sticky.getBoundingClientRect();
            if (height < (parentHeight - 200) && height < (viewportHeight - 200)) {
                sticky.style.position = '';
            } else {
                sticky.style.position = 'relative';
            }
        });
    };
    
    const onResize = () => {
        maybeStickStuff();
    };

    Viewport.on('resize', onResize);
    
    onResize();
    
    return {
        destroy() {
            Viewport.off('resize', onResize);
        }
    };

};
