import Viewport from '../core/Viewport';

export default (canvas, props = {}) => {
    
    const {
        svgUrl,
        svgWidth = 80,
        numInitialThings = 1,
        triggerSelector = null
    } = props;
    
    const ctx = canvas.getContext('2d');
    
    const triggers = triggerSelector ? document.querySelectorAll(triggerSelector) : [window];
    
    let raf;
    let img;
    let canvasW;
    let canvasH;
    let imgW;
    let imgH;
    let preventThings = false;
    let things = [];
    
    const setCanvasSize = () => {
        const {
            width,
            height
        } = canvas.parentNode.getBoundingClientRect();
        canvasW = width;
        canvasH = height;
        const dpr = window.devicePixelRatio;
        canvas.width = width * dpr;
        canvas.height = height * dpr;
        ctx.scale(dpr, dpr);
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
    };
    
    const getRandomInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    
    const getThing = () => ({
        x: getRandomInRange(0, canvasW - imgW),
        y: getRandomInRange(0, canvasH - imgH),
        dx: 4,
        dy: 4
    });
    
    const draw = () => {
        ctx.clearRect(0, 0, canvasW, canvasH);
        for (let i = 0; i < things.length; i += 1) {
            let {
                x,
                y,
                dx,
                dy
            } = things[i];
            ctx.drawImage(img, x, y, imgW, imgH);
            if (x < 0 || x > canvasW - imgW) {
                dx = -dx;
            }
            if (y < 0 || y > canvasH - imgH) {
                dy = -dy;
            }
            x += dx;
            y += dy;
            things[i] = {
                x,
                y,
                dx,
                dy
            };
        }
        raf = window.requestAnimationFrame(draw);
    };
    
    const startDrawing = () => {
        if (raf) {
            cancelAnimationFrame(raf);
        }
        let numThings = things.length;
        if (!numThings) {
            numThings = numInitialThings;
        }
        things = [];
        for (let i = 0; i < numThings; i += 1) {
            things.push(getThing());
        }
        draw();
    };
    
    let clickTimer = null;
    
    const onClick = e => {
        
        if (!img || preventThings) {
            return;
        }
        
        const {
            clientX,
            clientY
        } = e;
        if (clientX > canvasW || clientY > canvasH) {
            return;
        }
        
        e.preventDefault();
        
        const thing = getThing();
        const offsetX = Math.round(imgW / 2);
        const offsetY = Math.round(imgH / 2);
        const maxX = canvasW - imgW;
        const maxY = canvas.y - img.y;
        let x = clientX - offsetX;
        let y = clientY - offsetY;
        let {
            dx,
            dy
        } = thing;
        if (x < offsetX) {
            x = offsetX;
        } else if (x >= maxX) {
            x = maxX;
            dx = -dx;
        }
        if (y < offsetY) {
            y = offsetY;
        } else if (y >= maxY) {
            y = maxY;
        }
        if (e.currentTarget === window) {
            if (x > canvasW * 0.5) {
                dx = -dx;
            }
            if (y > canvasH * 0.5) {
                dy = -dy;
            }
        } else {
            if (getRandomInRange(0, 1)) {
                dx *= -1;
            }
            if (getRandomInRange(0, 1)) {
                dy *= -1;
            }
        }
        things.push({
            ...thing,
            x,
            y,
            dx,
            dy
        });
        
        if (clickTimer) {
            clearTimeout(clickTimer);
        }
        
        document.body.classList.add('!bg-yellow', '!text-black', 'select-none');
        document.documentElement.setAttribute('class', document.documentElement.getAttribute('class').replace('dark', 'darkx'));
        document.documentElement.setAttribute('class', document.documentElement.getAttribute('class').replace('should-invert', 'should-invertx'));
        document.documentElement.classList.add('disable-transitions');
        
        clickTimer = setTimeout(() => {
            document.body.classList.remove('!bg-yellow', '!text-black', 'select-none');
            document.documentElement.setAttribute('class', document.documentElement.getAttribute('class').replace('darkx', 'dark'));
            document.documentElement.setAttribute('class', document.documentElement.getAttribute('class').replace('should-invertx', 'should-invert'));
            setTimeout(() => {
                document.documentElement.classList.remove('disable-transitions');
                clickTimer = null;
            }, 10);
        }, 100);
        
    };
    
    const onMouseMove = e => {
        if (preventThings) {
            return;
        }
        const {
            clientX,
            clientY
        } = e;
        if (clientX > 0 && clientX <= canvasW && clientY > 0 && clientY <= canvasH) {
            document.documentElement.style.cursor = 'pointer';
        } else {
            document.documentElement.style.cursor = '';
        }
    };
    
    const onMouseEnterLink = () => {
        preventThings = true;
        document.documentElement.style.cursor = '';
    };
    
    const onMouseLeaveLink = () => {
        preventThings = false;
    };
    
    const teardown = () => {
        if (raf) {
            cancelAnimationFrame(raf);
            raf = null;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        things = [];
        if (!img) {
            return;
        }
        img = null;
        triggers.forEach(trigger => {
            trigger.removeEventListener('click', onClick);
            if (trigger === window) {
                trigger.removeEventListener('mousemove', onMouseMove);
            }
        });
        document.querySelectorAll('a')
            .forEach(link => {
                link.removeEventListener('mouseenter', onMouseEnterLink);
                link.removeEventListener('mouseleave', onMouseLeaveLink);
            });
    };
    
    const create = () => {
        
        if (img || canvas.offsetParent === null) {
            return;
        }
        
        setCanvasSize();
        
        img = new Image();
        img.src = svgUrl;
        img.onload = () => {
            const {
                naturalWidth,
                naturalHeight
            } = img;
            const width = svgWidth;
            const height = naturalHeight * (width / naturalWidth);
            img.width = width;
            img.height = height;
            imgW = img.width;
            imgH = img.height;
            startDrawing();
        };
        
        triggers.forEach(trigger => {
            trigger.addEventListener('click', onClick);
            if (trigger === window) {
                trigger.addEventListener('mousemove', onMouseMove);
            }
        });
        
        document.querySelectorAll('a')
            .forEach(link => {
                link.addEventListener('mouseenter', onMouseEnterLink);
                link.addEventListener('mouseleave', onMouseLeaveLink);
            });
        
    };
    
    const onResize = () => {
        if (canvas.offsetParent === null) {
            teardown();
            return;
        }
        setCanvasSize();
        create();
        startDrawing();
    };
    
    onResize();
    
    Viewport.on('resize', onResize);
    
    return {
        destroy() {
            teardown();
            Viewport.off('resize', onResize);
        }
    };
    
};
