import gsap from 'gsap';
import throttle from 'lodash/throttle';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { HOMEPAGE_CHANGE_VIEW, SCROLLING } from '../lib/events';
import { isSmall, isTouch } from '../lib/helpers';

export default el => {
    
    // Get the about and project nodes and their scrollables
    const about = document.getElementById('about');
    const aboutScrollable = about.querySelector('.scrollable');
    const projects = document.getElementById('projects');
    const projectsScrollable = projects.querySelector('.scrollable');
    
    // Toggles to switch between projects and about views on mobile
    const aboutToggle = document.getElementById('about-toggle');
    const projectsToggle = document.getElementById('projects-toggle');
    
    // The wrapper is animated when the view switches on mobile
    const wrapper = el.firstElementChild;
    
    let currentView = 'about';
    let activeView = null;
    let hasScrolled = false;
    
    const showProjects = () => {
        gsap.set(wrapper, { xPercent: -100 });
        projectsToggle.classList.add('hidden');
        aboutToggle.classList.remove('hidden');
        currentView = 'projects';
    };
    
    const showAbout = () => {
        gsap.set(wrapper, { xPercent: 0 });
        projectsToggle.classList.remove('hidden');
        aboutToggle.classList.add('hidden');
        currentView = 'about';
    };
    
    const changeView = view => {
        if (view === 'projects') {
            showProjects();
        } else if (view === 'about') {
            showAbout();
        } else {
            throw new Error(`Invalid view ${view}`);
        }
        Dispatch.emit(HOMEPAGE_CHANGE_VIEW, view);
    };
    
    const onViewToggleClick = e => {
        e.preventDefault();
        e.stopPropagation();
        changeView(e.currentTarget.getAttribute('href').replace('#', ''));
    };
    
    const getHasScrolled = () => {
        if (hasScrolled) {
            return true;
        }
        const { scrollTop: projectsScrollTop } = projectsScrollable;
        const { scrollTop: aboutScrollTop } = aboutScrollable;
        if (
            (projectsScrollTop > 0 && projectsScrollTop !== parseInt(window.sessionStorage.getItem('projects_scrolltop'), 10))
            || (aboutScrollTop > 0 && aboutScrollTop !== parseInt(window.sessionStorage.getItem('about_scrolltop'), 10))
        ) {
            hasScrolled = true;
        }
        return hasScrolled;
    };
    
    const onAboutMouseEnter = () => {
        about.classList.remove('grayscale', 'opacity-10', 'dark:opacity-20');
        
        if (isSmall()) {
            return;
        }
        
        projects.classList.add('grayscale');
        
        if (activeView !== 'about') {
            activeView = 'about';
            Dispatch.emit(HOMEPAGE_CHANGE_VIEW, 'about');
        }
        
        Dispatch.emit(SCROLLING, { target: aboutScrollable });
        
        if (!getHasScrolled()) {
            return;
        }
        
        projects.classList.add('opacity-10', 'dark:opacity-20');
    };
    
    const onAboutMouseLeave = () => {
        projects.classList.remove('grayscale', 'opacity-10', 'dark:opacity-20');
    };
    
    const onProjectsMouseEnter = () => {
        projects.classList.remove('grayscale', 'opacity-10', 'dark:opacity-20');
        
        if (activeView !== 'projects') {
            activeView = 'projects';
            Dispatch.emit(HOMEPAGE_CHANGE_VIEW, 'projects');
        }
        
        Dispatch.emit(SCROLLING, { target: projectsScrollable });
        
        if (isSmall() || !getHasScrolled()) {
            return;
        }
        
        about.classList.add('grayscale', 'opacity-10', 'dark:opacity-20');
    };
    
    const onProjectsMouseLeave = () => {
        about.classList.remove('grayscale', 'opacity-10', 'dark:opacity-20');
    };
    
    let wasSmall = isSmall();
    
    const onBreakpoint = () => {
        if (isSmall()) {
            onProjectsMouseLeave();
        }
        if (!wasSmall && isSmall() && activeView !== 'about') {
            activeView = 'about';
            Dispatch.emit(HOMEPAGE_CHANGE_VIEW, activeView);
        }
        if (wasSmall !== isSmall()) {
            Dispatch.emit(SCROLLING, { target: activeView === 'about' ? aboutScrollable : projectsScrollable });
        }
        wasSmall = isSmall();
    };
    
    const onAboutScroll = e => {
        Dispatch.emit(SCROLLING, e);
        if (!isSmall() && !isTouch()) {
            onAboutMouseEnter();
        }
    };
    
    const aboutScrollHandler = throttle(onAboutScroll, 1, { trailing: true });
    
    const onProjectsScroll = e => {
        if (!isSmall() || activeView === 'projects') {
            Dispatch.emit(SCROLLING, e);
        }
        if (!isSmall() && !isTouch()) {
            onProjectsMouseEnter();
        }
    };
    
    const projectsScrollHandler = throttle(onProjectsScroll, 1, { trailing: true });
    
    const onAboutFocusIn = () => {
        if (!isSmall() || currentView === 'about') {
            return;
        }
        showAbout();
    };
    
    const onProjectsFocusIn = () => {
        if (!isSmall() || currentView === 'projects') {
            return;
        }
        showProjects();
    };
    
    const init = () => {
        if (isSmall()) {
            projectsScrollable.scrollTo(0, 0, 0);
        }
        projectsToggle.addEventListener('click', onViewToggleClick);
        aboutToggle.addEventListener('click', onViewToggleClick);
        about.addEventListener('mouseenter', onAboutMouseEnter);
        about.addEventListener('touchstart', onAboutMouseEnter, { passive: true });
        about.addEventListener('mouseleave', onAboutMouseLeave);
        projects.addEventListener('mouseenter', onProjectsMouseEnter);
        projects.addEventListener('touchstart', onProjectsMouseEnter, { passive: true });
        projects.addEventListener('mouseleave', onProjectsMouseLeave);
        aboutScrollable.addEventListener('scroll', aboutScrollHandler);
        projectsScrollable.addEventListener('scroll', projectsScrollHandler);
        about.addEventListener('focusin', onAboutFocusIn);
        projects.addEventListener('focusin', onProjectsFocusIn);
        Viewport.on('breakpoint', onBreakpoint);
        changeView('about');
    };
    
    const destroy = () => {
        aboutToggle.removeEventListener('click', onViewToggleClick);
        projectsToggle.removeEventListener('click', onViewToggleClick);
        about.removeEventListener('mouseenter', onAboutMouseEnter);
        about.removeEventListener('touchstart', onAboutMouseEnter);
        about.removeEventListener('mouseleave', onAboutMouseLeave);
        projects.removeEventListener('mouseenter', onProjectsMouseEnter);
        projects.removeEventListener('touchstart', onProjectsMouseEnter);
        projects.removeEventListener('mouseleave', onProjectsMouseLeave);
        aboutScrollable.removeEventListener('scroll', aboutScrollHandler);
        projectsScrollable.removeEventListener('scroll', projectsScrollHandler);
        Viewport.off('breakpoint', onBreakpoint);
        about.addEventListener('focusin', onAboutFocusIn);
        projects.addEventListener('focusin', onProjectsFocusIn);
    };
    
    return {
        init,
        destroy
    };

};
