import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import { HOMEPAGE_CHANGE_VIEW, SCROLLING } from '../lib/events';
import { isSmall, lightOrDark } from '../lib/helpers';

export default (el, { isFrontpage }) => {
    
    const $el = $(el);
    
    const $settingsToggle = $el.find('[data-toggle]');
    const $settingsPanel = $el.find('[data-panel]');
    
    let settingsOpen = false;
    let lastColor = 'light';
    let lastDesign = 'on';
    let plausibleEventTimeout = 0;
    
    const { setCookie, getCookie } = window.vrsg;
    
    let scrollHandler;
    
    const openSettings = () => {
        if (settingsOpen) {
            return;
        }
        
        $settingsToggle.attr('aria-expanded', 'true');
        
        const settingsPanel = $settingsPanel.get(0);
        settingsPanel.hidden = false;
        
        gsap.set(settingsPanel, { y: -$settingsPanel.height() - 25 });
        gsap.to(settingsPanel, {
            duration: 1,
            y: 0,
            ease: 'elastic.out(1.1, 0.3)'
        });
        
        settingsOpen = true;
        
        if (isFrontpage) {
            Dispatch.on(SCROLLING, scrollHandler);
        } else {
            Viewport.on('scroll', scrollHandler);
        }
        
    };
    
    const closeSettings = (tween = true) => {
        if (!settingsOpen) {
            return;
        }
        
        $settingsToggle.attr('aria-expanded', 'false');
        
        const settingsPanel = $settingsPanel.get(0);
        const y = -$settingsPanel.height() - 25;
        
        if (tween) {
            gsap.to(settingsPanel, {
                duration: 0.1,
                y,
                ease: 'sine.in',
                onComplete: () => {
                    settingsPanel.hidden = true;
                }
            });
        } else {
            gsap.set(settingsPanel, { y });
            settingsPanel.hidden = true;
        }
        
        settingsOpen = false;
        
        if (isFrontpage) {
            Dispatch.off(SCROLLING, scrollHandler);
        } else {
            Viewport.off('scroll', scrollHandler);
        }
    };
    
    const onPanelToggleClick = () => {
        if (!settingsOpen) {
            openSettings();
        } else {
            closeSettings();
        }
    };
    
    const onSettingChange = e => {
        const input = e.triggerTarget;
        const { name, value } = input;
        let cookieValue = value;
        console.log('setting change', { name, value });
        
        if (name === 'color') {
            document.documentElement.classList.add('disable-transitions');
            
            if (value && (value.length === 7 || value.length === 4) && value[0] === '#') {
                input.classList.add('has-value');
                
                const isDark = lightOrDark(value) === 'dark';
                
                document.documentElement.style.setProperty('--color-foreground', isDark ? '#fff' : '#000');
                document.documentElement.style.setProperty('--color-background', value);
                document.documentElement.style.setProperty('--color-background-alternate', value);
                document.documentElement.style.setProperty('--image-overlay-opacity', isDark ? 0.15 : 0);
                
                if (isDark) {
                    cookieValue += ':dark';
                    document.documentElement.classList.add('dark', 'should-invert');
                } else {
                    cookieValue += ':light';
                    document.documentElement.classList.remove('dark', 'should-invert');
                }
                $settingsPanel.find('input[type="radio"][name="color"]').each(radio => {
                    radio.checked = false;
                });
            } else {
                $settingsPanel.find('input[type="color"][name="color"]').removeClass('has-value');

                document.documentElement.style.removeProperty('--color-foreground');
                document.documentElement.style.removeProperty('--color-background');
                document.documentElement.style.removeProperty('--color-background-alternate');
                document.documentElement.style.removeProperty('--image-overlay-opacity');
                
                if (value === 'dark') {
                    document.documentElement.classList.add('dark', 'should-invert');
                } else {
                    document.documentElement.classList.remove('dark', 'should-invert');
                }
            }
            
            setTimeout(() => {
                document.documentElement.classList.remove('disable-transitions');
            }, 10);
            
        } else if (name === 'design') {
            if (value === 'off') {
                document.documentElement.classList.add('plaintext');
                document.querySelectorAll('link[rel="stylesheet"]').forEach(stylesheet => { stylesheet.setAttribute('rel', 'stylesheetx'); });
            } else {
                document.documentElement.classList.remove('plaintext');
                document.querySelectorAll('link[rel="stylesheetx"]').forEach(stylesheet => { stylesheet.setAttribute('rel', 'stylesheet'); });
            }
        }

        setCookie(name, cookieValue);

        if (plausible) {
            clearTimeout(plausibleEventTimeout);
            plausibleEventTimeout = setTimeout(() => {
                let color = getCookie('color') || 'light';
                let design = getCookie('design') || 'on';
                
                if (color && color[0] === '#') {
                    color = 'custom';
                }
                
                if (color !== lastColor || design !== lastDesign) {
                    plausible('pageview', {props: { color: color, design: design }});
                    
                    lastColor = color;
                    lastDesign = design;
                }
            }, 500);
        }
    };
    
    const onBodyClickOrFocus = e => {
        if (!settingsOpen) {
            return;
        }
        const { target } = e;
        const toggle = $settingsToggle.get(0);
        const panel = $settingsPanel.get(0);
        if (target === toggle || target === panel || toggle.contains(target) || panel.contains(target)) {
            return;
        }
        closeSettings();
    };
    
    let prevScrollTop;
    
    const onScroll = (key, event) => {
        if (!settingsOpen) {
            return;
        }
        let scrollTop;
        if (isFrontpage) {
            scrollTop = event.target.scrollTop;
        } else {
            scrollTop = Viewport.scrollTop;
        }
        if (!prevScrollTop) {
            prevScrollTop = scrollTop;
            return;
        }
        if (Math.abs(scrollTop - prevScrollTop) <= 10) {
            return;
        }
        closeSettings();
        prevScrollTop = null;
    };
    
    scrollHandler = onScroll;
    
    const onHomepageChangeView = () => {
        if (isSmall()) {
            closeSettings();
        }
    };
    
    const init = () => {
        $settingsToggle.on('click', onPanelToggleClick);
        $el.on('change', 'input', onSettingChange);
        $el.on('input', 'input', onSettingChange);
        $('body').on('click', onBodyClickOrFocus);
        $('body').on('focusin', onBodyClickOrFocus);
        Dispatch.on(HOMEPAGE_CHANGE_VIEW, onHomepageChangeView);
        
        lastColor = getCookie('color') || 'light';
        lastDesign = getCookie('design') || 'on';
    };
    
    const destroy = () => {
        closeSettings(false);
        $settingsToggle.off('click');
        $el.off('change');
        $el.off('input');
        $('body').off('click', onBodyClickOrFocus);
        $('body').off('focusin', onBodyClickOrFocus);
        Dispatch.off(HOMEPAGE_CHANGE_VIEW, onHomepageChangeView);
        
    };
    
    return {
        init,
        destroy
    };
    
};
