import throttle from 'lodash/throttle';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { isSmall } from '../lib/helpers';

export default (el, props) => {
    
    const DRAW_INTERVAL = 15;
    const TRAIL_EXPIRE_TIMEOUT = 500;
    
    const $el = $(el);
    const $body = $('body');

    let hoverDefs = [];
    let $hoverContainer = null;
    let hasInitedImages = false;
    let currentlyActive = null;
    let hideTimeout = null;
    let unmoveTimeout = null;
    
    let lastPageX = 0;
    let lastPageY = 0;
    
    let isScrolling = false;
    let didEnterDuringScroll = false;
    let scrollTimeout = null;
    
    let activeImages = [];
    
    let drawRaf = null;
    let isEntered = false;
    let observer = null;

    const stopDrawing = () => {
        if (!drawRaf) {
            return;
        }
        cancelAnimationFrame(drawRaf);
        drawRaf = null;
    };

    const onScroll = e => {
        isScrolling = true;
        
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
            isScrolling = false;
            
            if (didEnterDuringScroll) {
                onEnterElement();
            }
        }, 100);
    };
    
    const startDrawing = () => {
        stopDrawing();
        if (!isEntered && !activeImages.length) {
            return;
        }
        drawRaf = requestAnimationFrame(draw);
    };
    
    const onEnterElement = () => {
        isEntered = true;
        
        if (!isScrolling) {
            if (!hasInitedImages) {
                initHoverImages();
            }

            showHoverContainer();
            
            $body.off('mousemove', onBodyMouseMove);
            $body.on('mousemove', onBodyMouseMove);
            
            clearTimeout(unmoveTimeout);
            
            startDrawing();
            
        } else {
            didEnterDuringScroll = true;
        }
    };

    const onLeaveElement = () => {
        
        clearTimeout(unmoveTimeout);
        
        didEnterDuringScroll = false;
        lastPageX = 0;
        lastPageY = 0;
        
        unmoveTimeout = setTimeout(() => {
            $body.off('mousemove', onBodyMouseMove);
            hideHoverContainer();
        }, TRAIL_EXPIRE_TIMEOUT);
        
        isEntered = false;
    };

    const initHoverImages = () => {
        if (hasInitedImages) {
            return;
        }
        hasInitedImages = true;
        $hoverContainer = $('<div>').addClass('absolute w-full h-full left-0 top-0 z-1000 overflow-hidden pointer-events-none hidden');
        $body.append($hoverContainer);
        $el.find('[data-hover-link]').each(item => {
            const $link = $(item);
            const linkId = $link.data('hover-link');
            const hoverImage = $link.data('hover-image');

            if (hoverImage) {
                const img = new Image();
                img.crossOrigin = 'anonymous';
                
                img.onload = function () {
                    const canvas = document.createElement("canvas");
                    canvas.width =this.width;
                    canvas.height =this.height;
            
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(this, 0, 0);
            
                    const dataURL = canvas.toDataURL("image/png");
            
                    //alert(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
                    
                    const $imageElement = $('<img>').attr('src', dataURL).addClass('absolute object-contain object-left-top brightness-95 dark:brightness-75 hidden pointer-events-none w-[150px] h-[150px]');
                    
                    hoverDefs[linkId] = {
                        $element: $link,
                        $image: $imageElement
                    };
        
                    $link.on('mouseenter', onMouseEnterLink).on('mouseleave', onMouseLeaveLink);
                };
            
                img.src = hoverImage.url;
            }
        });
        console.info('inited hover images');
    };
    
    const showHoverContainer = () => {
        if ($hoverContainer) {
            $hoverContainer.removeClass('hidden');
        }
    };

    const hideHoverContainer = () => {
        if ($hoverContainer) {
            $hoverContainer.addClass('hidden');
        }
    };

    const onMouseEnterLink = e => {
        clearTimeout(hideTimeout);

        if (!isScrolling) {
            currentlyActive = $(e.currentTarget).data('hover-link');
        }
    };

    const onMouseLeaveLink = e => {
        clearTimeout(hideTimeout);
        currentlyActive = null;
    };
    
    const doDraw = throttle(() => {
        if (currentlyActive === null) {
            return;
        }
        
        if (lastPageY === 0 && lastPageX === 0) {
            return;
        }
        
        const def = hoverDefs[currentlyActive];
        const $image = def.$image;
        
        if (!$image || $image.length === 0) {
            return;
        }
        
        const $newImage = $image.clone();
        
        activeImages.unshift({ $image: $newImage, ts: Date.now() });
        
        $hoverContainer.append($newImage.removeClass('hidden').css({ left: lastPageX + 30, top: lastPageY + 30 }));
        
    }, DRAW_INTERVAL, { leading: true, trailing: false });
    
    const draw = () => {
        doDraw();
        purgeImages();
        startDrawing();
    };

    const onBodyMouseMove = e => {
        lastPageX = e.pageX;
        lastPageY = e.pageY;
    };

    const purgeImages = () => {
        
        if (!activeImages.length) {
            return;
        }
        
        let firstIndex = null;
        
        activeImages.forEach((el, index)=> {
            if (el.ts + TRAIL_EXPIRE_TIMEOUT < Date.now()) {
                if (firstIndex === null) {
                    firstIndex = index;
                }
                el.$image.remove();
            }
        });
        
        if (firstIndex !== null) {
            activeImages.splice(firstIndex);
        }
    };
    
    if (!isSmall()) {
        Viewport.on('scroll', onScroll);
        $el.on('mouseenter', onEnterElement).on('mouseleave', onLeaveElement);
        
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isIntersecting && !hasInitedImages) {
                initHoverImages();
                observer.disconnect();
                observer = null;
            }
        });
        
        observer.observe(el);
    }
    
    return {
        destroy() {
            stopDrawing();
            
            $el.off('mouseenter', onEnterElement).off('mouseleave', onLeaveElement);
            
            $el.find('[data-hover-link]').each(item => {
                const $link = $(item);
                $link.off('mouseenter', onMouseEnterLink).off('mouseleave', onMouseLeaveLink);
            });
            
            clearTimeout(unmoveTimeout);
            clearTimeout(scrollTimeout);
            clearTimeout(hideTimeout);
            
            activeImages = [];
            
            if ($hoverContainer) {
                $hoverContainer.empty();
                $hoverContainer.remove();
            }
            
            if (observer) {
                observer.disconnect();
                observer = null;
            }
        }
    };
};
