import { loadThingsMatterScene } from '../lib/async-bundles';

export default (el, props) => {
    
    let scene;
    
    loadThingsMatterScene(({ default: ThingsScene }) => {
        scene = ThingsScene(el, props);
    });
    
    return {
        destroy() {
            if (scene) {
                scene.destroy();
            }
        }
    };
    
};
