import gsap from 'gsap';
import throttle from 'lodash/throttle';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { HOMEPAGE_CHANGE_VIEW, SCROLLING } from '../lib/events';
import { getViewportScrollTop, isSmall } from '../lib/helpers';

export default (el, props) => {
    
    const $el = $(el);
    const { isFrontpage, hasMultipleTaglines } = props;
    
    const $logoText = $el.find('[data-logo]');
    const $taglinesButton = $el.find('[data-taglines-button]'); // The button wrapping the taglines
    const $taglinesArrow = $el.find('[data-taglines-arrow]'); // An arrow inside the taglines button
    const $taglinesWrapper = $el.find('[data-taglines]'); // A container wrapping the individual taglines
    const $taglines = $el.find('[data-tagline]'); // The actual, individual taglines
    const $settingsToggle = $el.find('[data-settings-toggle]');
    const $frontPageToggles = $el.find('[data-frontpage-toggles]');
    
    const logoLineElems = [$logoText.get(0), $taglinesArrow.get(0), $taglinesWrapper.get(0), $settingsToggle.get(0), $frontPageToggles.get(0)].filter(node => !!node);

    let $dynamicTaglineWrapper = null;
    let currentTagline = 0;
    let logoCollapsed = false;
    let lastFrontpageView = '';
    let lastFrontpageTarget = null;
    
    const collapseLogoLine = () => {
        
        if (logoCollapsed) {
            return;
        }
        
        logoCollapsed = true;
        
        gsap.to(logoLineElems, { duration: 0.2, y: -65, ease: 'sine.out', stagger: 0.025, overwrite: true });
        
    };
    
    const expandLogoLine = () => {
        
        if (!logoCollapsed) {
            return;
        }
        
        logoCollapsed = false;
        
        gsap.to(logoLineElems, { duration: 0.8, delay: 0.1, y: 0, ease: 'elastic.out(1, 0.4)', stagger: 0.025, overwrite: true });
        
    };
    
    const onViewportScroll = () => {
        if (!logoCollapsed && getViewportScrollTop() > 30) {
            collapseLogoLine();
        }
        if (logoCollapsed && getViewportScrollTop() < 30) {
            expandLogoLine();
        }
    };
    
    const onHomepageChangeView = (key, data) => {
        lastFrontpageView = data;
    };
    
    const onHomepageScrolling = (key, data) => {
        
        lastFrontpageTarget = data.target;
        
        if (isSmall()) {
            if (!logoCollapsed && data.target.scrollTop > 30) {
                collapseLogoLine();
            }
            if (logoCollapsed && data.target.scrollTop < 30) {
                expandLogoLine();
            }
        } else {
            if (lastFrontpageView === 'about' && logoCollapsed) {
                expandLogoLine();
            }
            
            if (lastFrontpageView === 'projects' && !logoCollapsed && data.target.scrollTop > 30) {
                collapseLogoLine();
            }
            
            if (lastFrontpageView === 'projects' && logoCollapsed && data.target.scrollTop < 30) {
                expandLogoLine();
            }
        }
    };

    const onTaglinesDown = e => {
        gsap.to($dynamicTaglineWrapper.get(0), { x: 12, duration: 0.2, ease: 'expo.out' });
        gsap.to($taglinesArrow.get(0), { x: 5, duration: 0.2, ease: 'expo.out' });
    };
    
    const onTaglinesUp = e => {
        currentTagline += 1;
        
        if (currentTagline >= $taglines.length) {
            currentTagline = 0;
        }
        
        $dynamicTaglineWrapper.text($taglines.eq(currentTagline).text());
        gsap.to($dynamicTaglineWrapper.get(0), { x: 0, duration: 0.8, ease: 'elastic.out(1.1, 0.3)' });
        gsap.to($taglinesArrow.get(0), { x: 0, duration: 0.8, ease: 'elastic.out(1.1, 0.3)' });
    };
    
    const onButtonClick = e => {
        e.preventDefault();
        if (logoCollapsed) {
            if (isFrontpage) {
                if (lastFrontpageTarget) {
                    gsap.to(lastFrontpageTarget, { duration: 0.8, scrollTo: { y: 0 }, ease: 'power2.inOut' });
                }
            } else {
                gsap.to(window, { duration: 0.8, scrollTo: { y: 0 }, ease: 'power2.inOut' });
            }
        }
    };

    const initTaglines = () => {
        $taglinesButton.on('mousedown', onTaglinesDown);
        $taglinesButton.on('mouseup', onTaglinesUp);

        $taglines.attr('hidden', '');
        
        $dynamicTaglineWrapper = $('<span class="flex-none"></span>');
        $taglinesWrapper.append($dynamicTaglineWrapper);
        $dynamicTaglineWrapper.text($taglines.eq(currentTagline).text());
    };
    
    const viewportScrollHandler = throttle(onViewportScroll, 1, { trailing: true });
    
    const initLogoBehaviour = () => {
        if (!isFrontpage) {
            window.addEventListener('scroll', viewportScrollHandler);
        } else {
            Dispatch.on(HOMEPAGE_CHANGE_VIEW, onHomepageChangeView);
            Dispatch.on(SCROLLING, onHomepageScrolling);
        }
        
        $taglinesButton.on('click', onButtonClick);
    };

    const init = () => {
        if (hasMultipleTaglines) {
            initTaglines();
        }
        initLogoBehaviour();
    };
    
    const destroy = () => {
        $taglinesButton.off('mousedown', onTaglinesDown);
        $taglinesButton.off('mouseup', onTaglinesUp);
        $taglinesButton.off('click', onButtonClick);
        
        if (!isFrontpage) {
            window.removeEventListener('scroll', viewportScrollHandler);
        } else {
            Dispatch.off(HOMEPAGE_CHANGE_VIEW, onHomepageChangeView);
            Dispatch.off(SCROLLING, onHomepageScrolling);
        }
    };

    return {
        init,
        destroy
    };
};
