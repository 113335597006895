import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollToPlugin);

const init = () => {
    
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();
    
    // Anchor links
    $('body').on('click', 'a[href^="#"]', e => {
        if (document.documentElement.classList.contains('plaintext')) {
            return;
        }
        const targetId = e.triggerTarget.getAttribute('href').replace('#', '');
        const target = document.getElementById(targetId);
        if (!target) {
            return;
        }
        e.preventDefault();
        // Get the top scroll margin
        const scrollMargin = parseInt((window.getComputedStyle(target).scrollMarginTop || '0px').replace('px', ''), 10);
        // What are we scrolling exactly?
        const scrollable = target.closest('.scrollable');
        if (scrollable) {
            gsap.to(scrollable, { scrollTop: target.offsetTop - scrollMargin, duration: 0.75, ease: 'Cubic.easeInOut' });
        } else {
            // TODO
        }
    });
    
};

require('doc-ready')(init);
