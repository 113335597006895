export default el => {
    
    const video = el.firstElementChild;
    
    let observer;
    let timer;
    let isPlaying = false;
    let hasInited = false;
    
    const clearTimer = () => {
        if (!timer) {
            return;
        }
        clearTimeout(timer);
        timer = null;
    };
    
    const onTimeUpdate = e => {
        if (!timer) {
            return;
        }
        const { currentTime } = e.target;
        if (currentTime && currentTime > 0.001) {
            clearTimer();
            video.style.opacity = 1;
            isPlaying = true;
        }
    };
    
    const cantPlay = () => {
        clearTimer();
        isPlaying = false;
        if (video) {
            try {
                video.stop();
            } catch (error) {
                // Don't care
            }
            video.removeEventListener('timeupdate', onTimeUpdate);
            video.hidden = true;
        }
        const image = el.querySelector('img');
        if (image) {
            image.hidden = false;
        }
    };
    
    if (!window.customElements || !video) {
        cantPlay();
        return null;
    }
    
    const playAndCatch = () => {
        try {
            const promise = video.play();
            if (promise !== undefined) {
                promise.then().catch(e => {
                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                        cantPlay();
                    }
                });
            }
        } catch (error) {
            console.error(error);
            cantPlay();
        }
    };
    
    const createTimer = () => {
        timer = setTimeout(() => {
            clearTimer();
            cantPlay();
        }, 5000);
    };
    
    const initVideo = () => {
        
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isPlaying) {
                try {
                    if (isIntersecting) {
                        video.play();
                    } else {
                        video.pause();
                    }
                } catch (error) {
                    console.error(error);
                }
            } else if (isIntersecting && !hasInited) {
                video.addEventListener('timeupdate', onTimeUpdate);
                playAndCatch();
                createTimer();
                hasInited = true;
            }
        }, {
            rootMargin: '20% 0% 20% 0%'
        });
        
        observer.observe(el);
        
    };
    
    // Wrap initVideo in a promise to ensure the <mux-video> web component has loaded
    window.customElements.whenDefined('mux-video')
        .then(initVideo)
        .catch(error => {
            console.error(error);
            cantPlay();
        });
    
    return {
        destroy() {
            clearTimer();
            if (observer) {
                observer.disconnect();
                observer = null;
            }
            if (video) {
                video.removeEventListener('timeupdate', onTimeUpdate);
            }
        }
    };

};
