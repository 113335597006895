import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $burgers = $el.find('[data-burger]');

    let isPlaying = false;
    let isCreated = false;
    let timeline1 = null;
    let timeline2 = null;
    let timeline3= null;
    let observer = null;

    const init = () => {
        if ($burgers.length === 3) { // lazycode
            observer = new IntersectionObserver(([{ isIntersecting }]) => {
                if (isIntersecting) {
                    if (!isCreated) {
                        createBurgerDance();
                        play();
                    } else {
                        if (!isPlaying) {
                            play();
                        }
                    }
                } else {
                    if (isCreated && isPlaying) {
                        pause();
                    }
                }
    
            });
            observer.observe(el);
        }
    };
    
    const destroy = () => {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        
        if (isCreated) {
            killBurgerDance();
        }
    };
    
    const createBurgerDance = () => {
        killBurgerDance();
        
        // burger 1
        timeline1 = gsap.timeline({ paused: true, repeat: 999 });
        timeline1.to($burgers.get(0), {  duration: 0.15, ease: 'power1.inOut', x: 'random(-5, 5)', y: 'random(-20, -30)', rotate: 'random(-10, -15)', overwrite: false }, 0.1);
        timeline1.to($burgers.get(0), {  duration: 0.15, ease: 'power1.inOut', x: 0, y: 0, rotate: 0, overwrite: false }, 0.3);
        
        timeline1.to($burgers.get(0), {  duration: 0.15, ease: 'power1.inOut', x: 'random(-5, -10)', y: 'random(-20, -30)', rotate: 'random(5, 10)', overwrite: false }, 0.6);
        timeline1.to($burgers.get(0), {  duration: 0.15, ease: 'power1.inOut', x: 0, y: 0, rotate: 0, overwrite: false }, 0.8);
        
        // burger 2
        timeline2 = gsap.timeline({ paused: true, repeat: 999 });
        timeline2.to($burgers.get(1), {  duration: 0.2, ease: 'power1.inOut', x: 'random(10, 15)', y: 'random(-40, -45)', rotate: 'random(10, 15)', overwrite: false }, 0.2);
        timeline2.to($burgers.get(1), {  duration: 0.2, ease: 'power1.inOut', x: 0, y: 0, rotate: 0, overwrite: false }, 0.5);
        
        timeline2.to($burgers.get(1), {  duration: 0.2, ease: 'power1.inOut', x: 'random(-10, -20)', y: 'random(-35, -45)', rotate: 'random(-10, -15)', overwrite: false }, 0.8);
        timeline2.to($burgers.get(1), {  duration: 0.2, ease: 'power1.inOut', x: 0, y: 0, rotate: 0, overwrite: false }, 1.1);
        
        timeline2.to($burgers.get(1), {  duration: 0.2, ease: 'power1.out', x: 'random(-2, 2)', y: 'random(-50, -65)', rotate: 0, overwrite: false }, 1.4);
        timeline2.to($burgers.get(1), {  duration: 0.2, ease: 'power1.in', x: 0, y: 0, rotate: 0, overwrite: false }, 1.7);
        
        // burger 3
        timeline3 = gsap.timeline({ paused: true, repeat: 999 });
        timeline3.to($burgers.get(2), {  duration: 0.15, ease: 'power1.inOut', x: 'random(-5, 5)', y: 'random(-10, -15)', rotate: 'random(-5, -2)', overwrite: false }, 0.15);
        timeline3.to($burgers.get(2), {  duration: 0.15, ease: 'power1.inOut', x: 0, y: 0, rotate: 0, overwrite: false }, 0.4);
        
        timeline3.to($burgers.get(2), {  duration: 0.15, ease: 'power1.inOut', x: 'random(5, 10)', y: 'random(-8, -18)', rotate: 'random(5, 10)', overwrite: false }, 0.65);
        timeline3.to($burgers.get(2), {  duration: 0.15, ease: 'power1.inOut', x: 0, y: 0, rotate: 0, overwrite: false }, 0.9);
        
        isCreated = true;
        isPlaying = true;
    };
    
    const killBurgerDance = () => {
        if (isCreated) {
            timeline1.kill();
            timeline2.kill();
            timeline3.kill();
            timeline1 = null;
            timeline2 = null;
            timeline3 = null;
            isCreated = false;
            isPlaying = false;
        }        
    };
    
    const play = () => {
        timeline1.play();
        timeline2.play();
        timeline3.play();
        isPlaying = true;
    };

    const pause = () => {
        timeline1.pause();
        timeline2.pause();
        timeline3.pause();
        isPlaying = false;
    };
    
    return {
        init,
        destroy
    };
};
