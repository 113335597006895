import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';
import Physics2DPlugin from 'gsap/Physics2DPlugin';

gsap.registerPlugin(Physics2DPlugin);

export default (el, props) => {
    const $el = $(el);
    const $button = $el.find('button');
    let $emojiis = null;

    const { i18n } = props;

    let step = 1;

    const init = () => {
        $button.on('click', onEmojiiiii);
        
        $emojiis = $('<div class="emojiis"></div>');
        $('body').append($emojiis);
    };

    const destroy = () => {
        $button.off('click', onEmojiiiii);
    };

    const onEmojiiiii = () => {
        $button.width($button.width());
        if (step === 1) {
            $button.text(i18n.text2);
            step = 2;
        } else if (step === 2) {
            $button.text(i18n.text3);
            step = 3;

            emojiis()
        }
    };

    const randomInt = (min, max) => {
        return Math.floor(min + (Math.random() * (max - min)));
    }

    const emojiis = () => {
        const master = gsap.timeline({
            onComplete: () => {
                $button.text(i18n.text1);
                $button.width('');
                step = 1;
            }
        });
        master.add(control());
    }

    const control = () => {
        var dots = gsap.timeline(),
            qty = 80,
            duration = 4,
            emojiis = '🤗 😍 😘️ 🎉 💻 🍔 ❤️ 💖️ 🥳 🤩 📱 🪄 🦄 🦖 🐶 🌈'.split(' ');

        for (let i = 0; i < qty; i++) {
            const $dot = $("<div class='emojii'>" + emojiis[randomInt(0, emojiis.length)] + "</div>");
            const delay = Math.random() * (duration - 1);

            $dot.css('left', $button.offset().left + ($button.width()/2));
            $emojiis.append($dot)

            dots.to($dot.get(0), { duration: duration, physics2D: { velocity: randomInt(window.innerHeight * 1.1, window.innerHeight * 1.4), angle: Viewport.width > 750 ? 'random(250,290)' : 'random(260,280)', gravity: window.innerHeight + 35 } }, delay);
        }
        return dots;
    }


    return {
        init,
        destroy
    };
};
