import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $scroller = $el.find('[data-scroller]');
    const $speedNav = $el.find('[data-speed-nav]');
    const $imagesWrapper = $el.find('[data-images-wrapper]');

    let isCreated = false;
    let isPlaying = false;
    let timeline = null;
    let baseDuration = 20;
    let observer = null;

    const init = () => {
        $scroller.append($imagesWrapper.clone());
        
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isIntersecting) {
                if (!isCreated) {
                    create();
                    play();
                } else {
                    if (!isPlaying) {
                        play();
                    }
                }
            } else {
                if (isCreated && isPlaying) {
                    pause();
                }
            }

        });
        observer.observe(el);
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        
        if (timeline) {
            timeline.kill();
        }
        
        $speedNav.off('click');
    };
    
    const create = () => {
        $scroller.find('.lazyload').addClass('lazypreload');

        timeline = gsap.timeline({ repeat: 999, paused: true });
        timeline.to($scroller.get(0), { duration: baseDuration, ease: 'linear', x: '-50%' });

        $speedNav.on('click', 'button', onSpeedToggle);

        isCreated = true;
    };
    
    const play = () => {
        if (timeline) {
            timeline.play();
            isPlaying = true;
        }
    };

    const pause = () => {
        if (timeline) {
            timeline.pause();
            isPlaying = false;
        }
    };

    const onSpeedToggle = (e) => {
        const $triggerTarget = $(e.triggerTarget);
        const speed = $triggerTarget.data('speed');

        timeline.timeScale(speed);

        $speedNav.find('button').removeClass('selected').attr('aria-pressed', 'false');
        $triggerTarget.addClass('selected').attr('aria-pressed', 'true');
    };

    return {
        init,
        destroy
    };
};
