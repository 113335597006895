import $ from '../core/Dom';
import gsap from 'gsap';
import Viewport from '../core/Viewport';

export default (el, props) => {
    const $el = $(el);
    const { isExternal } = props;
    
    const $arrowHolder = $el.find('[data-arrow-holder]');
    const $arrowOriginal = $el.find('[data-arrow="original"]');
    const $textElements = $el.find('[data-text-element]');
    
    let isCreated = false;
    let timeline = null;
    let extraArrows = [];

    const init = () => {
        $el.on('mouseenter', onMouseEnter);
        $el.on('mouseleave', onMouseLeave);

        //$arrowHolder.css('width', getAvailableWidth()+'px');
        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', kill);
    };

    const destroy = () => {
        kill();
    };
    
    const kill = () => {
        if (isCreated) {
            timeline.kill();
            timeline = null;

            $arrowHolder.find('[data-arrow="cloned"]').remove();
            extraArrows = [];

            isCreated = false;
        }
    };

    const onResize = () => {
        kill();
    };
    
    const onMouseEnter = e => {
        
        if (!isCreated) {
            create();
        }

        if (timeline) {
            gsap.set(timeline, { progress: 0 });
            gsap.to(timeline, { duration: 1, progress: 0.5, ease: 'linear', overwrite: true });
        }
    };

    const onMouseLeave = e => {
        if (timeline) {
            gsap.set(timeline, { progress: 0.5 });
            gsap.to(timeline, { duration: 0.2, progress: 1, ease: 'linear', overwrite: true });
        }
    };
    
    const getAvailableWidth = () => {
        let r = $el.width();
        
        $textElements.each(element => {
            r -= element.clientWidth;
        });
        
        return r;
    };
    
    const create = () => {
        if (isCreated) {
            kill();
        }
        
        // Get the arrow width (width + margin-right)
        let { width: arrowWidth, marginRight: arrowMarginRight } = window.getComputedStyle($arrowOriginal.get(0));
        arrowMarginRight = parseFloat(arrowMarginRight.replace('px', ''));
        arrowWidth = parseFloat(arrowWidth.replace('px', '')) + arrowMarginRight;
        
        const numArrows = Math.min(Math.floor(getAvailableWidth() / arrowWidth) - 1, 6);
        
        for (let i = 0; i < numArrows; i++) {
            const $clonedArrow = $arrowOriginal.clone();
            $clonedArrow.removeClass('relative').addClass('absolute left-0 opacity-0').data('arrow', 'cloned');
            $arrowHolder.append($clonedArrow);
            extraArrows.push($clonedArrow);
        }

        timeline = gsap.timeline({ paused: true });
        
        timeline.to($arrowHolder.get(0), { duration: 1, width: ((extraArrows.length + 1) * arrowWidth), ease: 'elastic.out(1.1, 0.3)' });
        timeline.to($arrowHolder.get(0), { duration: 1, width: arrowWidth, ease: 'sine.in' }, 1);
        
        extraArrows.forEach(($arrow, index) => {
            timeline.to($arrow.get(0), { duration: 0.05, opacity: 1, ease: 'linear' }, 0);
            timeline.to($arrow.get(0), { duration: 1, x: (1 + index) * arrowWidth, rotate: isExternal ? -45 * ((index+1)/extraArrows.length) : 0, ease: 'elastic.out(1.1, 0.3)' }, 0);

            timeline.to($arrow.get(0), { duration: 0.05, opacity: 0, ease: 'linear' }, 1.95);
            timeline.to($arrow.get(0), { duration: 1, x: 0, rotate: 0, ease: 'sine.in' }, 1);
        });

        isCreated = true;
    };

    return {
        init,
        destroy
    };
};
