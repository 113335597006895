/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const SCROLLING = 'SCROLLING';
export const HOMEPAGE_CHANGE_VIEW = 'HOMEPAGE_CHANGE_VIEW';
